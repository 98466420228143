#app {
  height: 100%
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: 'Montserrat';
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.defineText {
  margin-top: 5%;
  text-align: center;
  font-size: 10.8vw;

  font-style: normal;
  font-weight: 400;


  color: #FFFFFF;
  -webkit-text-stroke: 1px #E5586D;

  text-shadow: 0px 0px 25px #E5586D;
}

.divWaveImg {
  margin-top: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* height: 100px;
    width: 50vw; */
  align-self: center;
  justify-content: center;
  justify-self: center;
}

.waveImg {
  height: 182px;
  width: 45vw;
}

.portfolioText {
  font-size: 1vw;
  color: white;
  width: 14%;
  text-align: center;
}

.textlabelinvest {
  text-align: center;
  align-items: center;
  margin-top: 2%;
  display: flex;
  margin-left: 28%;
  justify-content: center;
  justify-self: center;
  align-self: center;
  width: 44vw;
  /* background: radial-gradient(#151515 at 100% 0%, #17B384 100% 0%, #FFFFFF 100%,0); */
  /* background: linear-gradient(180deg, 
#4821bc 0.00%, 
#4608c3 100.00%); */
border: 1px solid;

border-image-source: radial-gradient(54.8% 53% at 50% 50%, #151515 0%, rgba(21, 21, 21, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
radial-gradient(60% 51.57% at 50% 50%, #17B384 0%, rgba(23, 179, 132, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

background: radial-gradient(54.8% 53% at 50% 50%, #151515 0%, rgba(21, 21, 21, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
radial-gradient(60% 51.57% at 50% 50%, #17B384 0%, rgba(23, 179, 132, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

background: radial-gradient(76.75% 76.75% at 70% 23.25%, #E7CE4A 0%, #E64467 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

/* background: linear-gradient(180deg, 
#E7CE4A 0.00%, 
#E64467 100.00%); */
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 2vw;
  font-weight: bold;
}

.display-counter {
  margin-top: 5%;
  text-align: center;
  font-size: 11.8vw;
  color: #eee;
  font-weight: 100;

  color: #FFFFFF;

  -webkit-text-stroke: 1px #D31D49;
  /* border: 1px solid #D31D49; */
  text-shadow: 0px 0px 25px #D31D49;

}

.display-counter-cr {
  margin-top: 5%;
  text-align: center;
  font-size: 11.8vw;
  color: #eee;

  font-weight: 100;
  margin-left: 5px;
  /* border: 1px solid #D31D49; */
  -webkit-text-stroke: 1px #D31D49;
  text-shadow: 0px 0px 25px #D31D49;

}

.app {
  columns: 3;
  display: grid;
  grid-template-columns: 50vw 10px;
}


li.nav-item.space-menus {
  margin-left: 12%;
}

img.img-fluid.sign-in-logo {
  width: 15%;
  height: 100%;
  margin-left: auto;
  display: block;
  margin-right: 1px;
}

.form-control-borderless {
  border: none;
}

.form-control-borderless:hover,
.form-control-borderless:active,
.form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.search-bar {
  width: 27% !important;
}

button.btn.btn-outline-secondary {
  border-right: 0px solid;
  background: rgba(153, 134, 250, 0.23);
  border-radius: 50px;
}

.navbar-toggler {

  color: #BEAEFF;
  background-color: transparent !important;
}


.nav-link {
  color: #BEAEFF !important;

}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 0px 50px 50px 0px;
  border-left: 0px;
  background: rgba(153, 134, 250, 0.23);
  text-align: center;
  font-weight: 800;
  color: #BEAEFF;

}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #BEAEFF !important;

}

.navbar-brand {
  margin-right: 14% !important;
  width: 5%;
}

.navbar {
  background: radial-gradient(farthest-corner at 50% 50%, rgba(9, 6, 32, 1) 100%, rgba(91, 57, 220, 1) 100%);
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbar-brand {
    margin-right: 0% !important;
    width: 18%;
  }

  .search-bar {
    width: 50% !important;
  }

  img.img-fluid.sign-in-logo {




    margin-right: 1px;
    margin-left: 48px;
  }

  .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {

    padding: 0px;
  }

  .waveImg {
    height: 108px;
    width: 91vw;
  }

  .moible-space {
    width: 92vw !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}